import ErrorPage404 from '@/components/ErrorPage404/ErrorPage404'
import DfsHeader from '@/components/Header/Dfs/Dfs'
import { Footer } from '@fanduel/og-fe-library'

const FourOhFour = () => (
  <>
    <DfsHeader />
    <ErrorPage404 />
    <Footer />
  </>
)

export default FourOhFour
