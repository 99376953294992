import { mediaEmotion } from '@/utils/general'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Image from 'next/image'

export const ErrorPage404Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(53, 53, 53);
  padding: 32px 10px 50px 10px;

  ${mediaEmotion.sm(css`
    padding: 40px 10px;
  `)}
`

export const ScoreboardImage = styled(Image)`
  max-width: 100%;
  height: fit-content;
  object-fit: contain;
  margin-bottom: 32px;

  ${mediaEmotion.sm(css`
    margin-bottom: 40px;
  `)}
`

export const ScoreboardMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 27px;
`

export const ScoreboardChar = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 26px;
  color: rgb(238, 238, 238);
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 20px;
  margin: 0px 2px;
  box-shadow:
    rgba(50, 50, 50, 0.7) 0px -0.07em 0px inset,
    rgba(0, 0, 0, 0.7) 0px -0.14em 0px inset,
    rgba(0, 0, 0, 0.9) 0.14em 0px 0.28em inset,
    rgba(0, 0, 0, 0.9) -0.14em 0px 0.28em inset,
    rgba(255, 255, 255, 0.2) 0px 0.07em 0px;

  ${mediaEmotion.sm(css`
    width: 30px;
    height: 39px;
    font-size: 30px;
    margin: 0px 3px;
  `)}
`

export const DescriptionText = styled.p`
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 14px;

  ${mediaEmotion.sm(css`
    font-size: 24px;
    margin-bottom: 16px;
  `)}
`

export const CtaButton = styled.button`
  cursor: pointer;
  background-image: linear-gradient(-180deg, rgb(54, 197, 101) 0%, rgb(44, 180, 89) 100%);
  color: rgb(255, 255, 255);
  font-size: 22px;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;

  ${mediaEmotion.sm(css`
    font-size: 24px;
  `)}
`
