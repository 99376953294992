import {
  ScoreboardChar,
  ScoreboardImage,
  CtaButton,
  DescriptionText,
  ScoreboardMessageContainer,
  ErrorPage404Root,
} from './ErrorPage404.styled'
import Link from 'next/link'
const ErrorPage404 = () => {
  const scoreboardMessage = 'PAGE NOT FOUND'

  return (
    <ErrorPage404Root>
      <ScoreboardImage
        width={640}
        height={228}
        src="https://s3.amazonaws.com/cdn.fanduel.com/images/pages/404/404-scoreboard.gif"
        alt="404 Scoreboard Image"
        priority
      />
      <ScoreboardMessageContainer>
        {scoreboardMessage.split('').map((char, index) => (
          <ScoreboardChar key={`${index}-${char}`}>{char}</ScoreboardChar>
        ))}
      </ScoreboardMessageContainer>
      <DescriptionText>{"Sorry, we couldn't find the page you were looking for"}</DescriptionText>
      <Link href="https://www.fanduel.com/">
        <CtaButton>Go to the home page</CtaButton>
      </Link>
    </ErrorPage404Root>
  )
}

export default ErrorPage404
